body { font-family: 'Poppins'; }
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 52px;
	height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}


/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #2196F3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.table-hover tbody tr:hover {
	color: #212529;
	background-color: rgba(0, 123, 255, 0.15) !important;
}
.modal .modal-content{
	border-radius : 1.05rem !important;
}
.modal .modal-header{
	border-top-left-radius : 1rem !important;
	border-top-right-radius : 1rem !important;
}
.modal .modal-footer{
	border-bottom-left-radius : 1rem !important;
	border-bottom-right-radius : 1rem !important;
}

/*GAGANDEEP SINGH >>>>>*/
#dprTable tr:hover{
	color: black !important;
}
.callout.callout-navy {
	border-left-color: #001f3f;
}
.btn-outline-navy:not(:disabled):not(.disabled).active, .btn-outline-navy:not(:disabled):not(.disabled):active, .show>.btn-outline-navy.dropdown-toggle {
	color: #fff;
	background-color: #001f3f;
	border-color: #001f3f;
}
.btn-outline-navy {
	color: #001f3f;
	border-color: #001f3f;
}
.btn-outline-navy:hover {
	color: #fff !important;
	background-color: #001f3f;
	border-color: #001f3f;
}
.btn-navy:not(:disabled):not(.disabled).active, .btn-navy:not(:disabled):not(.disabled):active, .show>.btn-navy.dropdown-toggle {
	color: #fff;
	background-color: #001f3f;
	border-color: #001f3f;
}
.btn-navy {
	color: #fff;
	background-color: #001f3f;
	border-color: #001f3f;
}
.btn-navy:hover {
	color: #001f3f !important;
	background-color: #fff;
	border-color: #001f3f;
}
.text-navy{
	color: #001f3f !important;
}
a.text-dec-none{
	text-decoration: none !important;
}
.login-box {
	width: 500px;
	color: #001f3f !important;
}
.login-box .card {
	border-radius: 5px;
	box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.login-box .login-card-body {
	border-radius: 5px;
	padding: 4rem 5rem 4rem 5rem;
}
@media (max-width: 575px) {
	.login-box {
		width: auto;
	}
	.login-box .login-card-body {
		padding: 5rem 1rem;
	}

}
.nav-tabs .nav-link .navbar-badge{
	position: relative;
	right: 0px;
	top: 0px;
}
.cursor-pointer{
	cursor: pointer !important;
}
/*GAGANDEEP SINGH <<<<<*/
.lightbox-target {
	position: fixed;
	top: -100%;
	width: 100%;
	background: rgba(0,0,0,.8);
	width: 100%;
	opacity: 0;
	-webkit-transition: opacity .5s ease-in-out;
	-moz-transition: opacity .5s ease-in-out;
	-o-transition: opacity .5s ease-in-out;
	transition: opacity .5s ease-in-out;
	overflow: hidden;
}

.lightbox-target img {
	margin: auto;
	position: absolute;
	top: 0;
	left:0;
	right:0;
	bottom: 0;
	max-height: 0%;
	max-width: 0%;
	border: 3px solid white;
	box-shadow: 0px 0px 8px rgba(0,0,0,.3);
	box-sizing: border-box;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out; 
}

a.lightbox-close:before {
	content: "";
	display: block;
	height: 30px;
	width: 1px;
	background: black;
	position: absolute;
	left: 26px;
	top:10px;
	-webkit-transform:rotate(45deg);
	-moz-transform:rotate(45deg);
	-o-transform:rotate(45deg);
	transform:rotate(45deg);
}

a.lightbox-close:after {
	content: "";
	display: block;
	height: 30px;
	width: 1px;
	background: black;
	position: absolute;
	left: 26px;
	top:10px;
	-webkit-transform:rotate(-45deg);
	-moz-transform:rotate(-45deg);
	-o-transform:rotate(-45deg);
	transform:rotate(-45deg);
}

a.lightbox-close {
	display: block;
	width:50px;
	height:50px;
	box-sizing: border-box;
	background: white;
	color: black;
	text-decoration: none;
	position: absolute;
	top: -80px;
	right: 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
}

.lightbox-target:target {
	opacity: 1;
	top: 0;
	bottom: 0;
	overflow:scroll;
	z-index: 2;
}

.lightbox-target:target img {
	max-height: 100%;
	max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
	top: 70px;
}